@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Lexend&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Send+Flowers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

@import "react-alice-carousel/lib/alice-carousel.css";

html, body {
  @apply absolute top-0 bottom-0 right-0 left-0 overflow-hidden scrollbar-hide;
  overscroll-behavior-y: none;
  overflow-y: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  transition: all;
  transition: 200ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.clickable {
  transition: all;
  transition: 100ms;
}

.clickable:active:hover,
  .clickable:active:focus {
    transform: scale(0.95, 0.95);
    transition: 100ms;
}

.animate-out {
  transform: scale(0.9, 0);
}

.animate-in {
  transform: scale(1, 1);
  opacity: 100%;
  transition: all;
  transition: 200ms;
}


.scale-out {
  transform: scale(0.8, 0.8);
}

.cardprompt-out {
  transform: scale(0.6, 0.6);
  opacity: 0%;
}



.twist-45 {
  transform: rotate(45deg);
  transition: all;
  transition: 200ms;
}

.untwist-45 {
  transform: rotate(0deg);
  transition: all;
  transition: 200ms;
}

.twist-90 {
  transform: rotate(90deg);
  transition: all;
  transition: 200ms;
}
.-twist-90 {
  transform: rotate(-90deg);
  transition: all;
  transition: 200ms;
}

.twist-270 {
  transform: rotate(270deg);
  transition: all;
  transition: 200ms;
}

.untwist {
  transform: rotate(0deg);
  transition: all;
  transition: 200ms;
}


/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #edf2f700;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100vh;
  border: 3px solid #edf2f700;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec000;
}


.theme-gradient {
  background: -webkit-linear-gradient(60deg, #f43f5e 0%, #a855f7 100%);
}





