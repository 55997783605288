
.progressVisualFull {
    display: flex;
    /* change the value if you want different thickness */
    height: 10px;
    background: var(--theme-mode-background);

  }
  
  .progressVisualPart {
    /* Number of the seconds for complete animation */
    transition: width 0.8s;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  
  .progressLabel {
    /* Nothing here, move along */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  }